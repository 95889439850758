.header {
    color: #323131;
    font-size: 18px;
    font-weight: 700;
}

.message {
    margin-bottom: 32px;
    white-space: pre;
}
