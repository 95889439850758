.root {
    width: 100%;
    height: 100%;
}

.iframe {
    width: 100%;
    height: 100%;
    border: none;
    overflow-y: hidden;
    overflow-x: hidden;
}
