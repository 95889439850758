*,
::after,
::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    color: #555;
    font: 400 14px HKNova, sans-serif;
    line-height: normal;
    letter-spacing: 0px;
}

body a {
    color: #3399ff;
}

.page {
    display: flex;
    min-height: 100vh;
}

@media (max-width: 1024px) {
    .page {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
}
