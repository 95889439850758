.login__browser {
    display: flex;
    align-items: center;
    font-size: 13px;
}

.login__browserText {
    line-height: 1.15;
    letter-spacing: -0.1px;
    color: #939ba4;
    word-break: keep-all;
    word-wrap: break-word;
    width: calc(100% + 20px);
    display: inline-block;
    padding: 0;
    margin: 0;
}

.login__browserLogo {
    margin-right: 8px;
    transform: translateY(2px);
    filter: grayscale(1);
}
