.input {
    margin-bottom: 16px;
}
.input_label {
    color: #666;
    font-size: 13px;
    margin-bottom: 5px;
    display: block;
}
.input_input {
    width: 100%;
    height: 44px;
    border: solid 1px #d8d8d8;
    padding: 0 17px;
    font-size: 15px;
    box-sizing: border-box;
    outline: none;
}
.input_error {
    border-color: #ff5c5c;
}
.input_wrapper {
    position: relative;
}

.input_endAdornment {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.input_endAdornment > button {
    -webkit-appearance: button;
    appearance: button;
    background: transparent;
    border-radius: 0;
    border: none;
    color: inherit;
    padding: 3px;
    text-transform: none;
}
.input_endAdornment > button > svg {
    width: 24px;
    fill: #666;
}
.input_endAdornment > button:focus:not(:focus-visible) {
    outline: 0;
}
.input_endAdornment > button:not(:disabled) {
    cursor: pointer;
}
