.root {
    background-image: url('../../../../assets/images/pattern.svg');
    background-size: cover;
    background-color: #282837;
    background-position: center;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .root {
        flex-grow: 1;
        width: 100%;
    }
}
