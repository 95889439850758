@font-face {
    font-family: HKNova;
    src: local(HKNova-Thin), url('./hknova-thin.woff2') format('woff2'), url('./hknova-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: HKNova;
    src: local(HKNova-ExtraLight), url('./hknova-extralight.woff2') format('woff2'),
        url('./hknova-extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: HKNova;
    src: local(HKNova-Light), url('./hknova-light.woff2') format('woff2'), url('./hknova-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: HKNova;
    src: local(HKNova-Regular), url('./hknova-regular.woff2') format('woff2'),
        url('./hknova-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: HKNova;
    src: local(HKNova-Medium), url('./hknova-medium.woff2') format('woff2'), url('./hknova-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: HKNova;
    src: local(HKNova-SemiBold), url('./hknova-semibold.woff2') format('woff2'),
        url('./hknova-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: HKNova;
    src: local(HKNova-Bold), url('./hknova-bold.woff2') format('woff2'), url('./hknova-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: HKNova;
    src: local(HKNova-ExtraBold), url('./hknova-extrabold.woff2') format('woff2'),
        url('./hknova-extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: HKNova;
    src: local(HKNova-Heavy), url('./hknova-heavy.woff2') format('woff2'), url('./hknova-heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
}
