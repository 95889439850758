.digicert {
    padding-top: 31px;
    display: flex;
    align-items: center;
}

.digicert__spacing {
    margin-top: auto;
    width: 290px;
    margin: 0 auto;
}

.digicert__icon {
    margin-right: 10px;
    width: 30%;
    flex-shrink: 0;
}

.digicert__icon {
    margin-right: 10px;
    width: 30%;
    flex-shrink: 0;
}

.digicert__icon img {
    max-width: 100% !important;
    height: auto !important;
    object-fit: contain !important;
}

@supports (image-rendering: -webkit-optimize-contrast) {
    .digicert__icon img {
        image-rendering: -webkit-optimize-contrast;
    }
}
