.button {
    height: 44px;
    width: 100%;
    border: 0;
    padding: 0 40px;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button:hover {
    background-image: linear-gradient(108deg, #8bca1e, #74ae17);
}
.button:disabled {
    pointer-events: none;
    user-select: none;
}
.contained {
    background-color: #82bc2f;
    color: #fff;
}
.outlined {
    background-color: #fff;
    border: solid 1px #3399ff;
    color: #3399ff;
}
.loader {
    height: 32px;
    width: 32px;
    display: inline-block;
    animation: loader-1-1 4.8s linear infinite;
}
@keyframes loader-1-1 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    clip: rect(0, 32px, 32px, 16px);
    animation: loader-1-2 1.2s linear infinite;
}
@keyframes loader-1-2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(220deg);
    }
}
.loader span::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    clip: rect(0, 32px, 32px, 16px);
    border-radius: 50%;
    animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

.loaderContained span::after {
    border: 3px solid #fff;
}

.loaderOutlined span::after {
    border: 3px solid #3399ff;
}

@keyframes loader-1-3 {
    0% {
        transform: rotate(-140deg);
    }
    50% {
        transform: rotate(-160deg);
    }
    100% {
        transform: rotate(140deg);
    }
}
