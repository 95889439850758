.root {
    width: 290px;
    margin: 0 auto;
}

.login__contact {
    color: #323131;
}

@media (max-width: 1024px) {
    .root {
        padding-top: 32px;
    }
}
