.header,
.body,
.body:not(:last-child) {
    margin-bottom: 16px;
}

.bold {
    font-weight: 700;
    color: #323131;
    font-size: 13px;
}

.list {
    padding: 0;
}

.listItem {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.listItemTitle {
    width: 28%;
}

.listIcon {
    margin-right: 8px;
    width: 24px;
    fill: #555;
}

.mobile {
    margin-bottom: 16px;
}
