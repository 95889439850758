.loginFormWrapper {
    min-height: 308px;
}

.login__container {
    min-width: 360px;
    padding: 50px calc((30vw - 290px) / 2) 30px;
    width: 30vw;
    display: flex;
    flex-direction: column;
}

.login__content {
    flex-basis: 100%;
    width: 290px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

@media (max-width: 1024px) {
    .login__container {
        flex-grow: 0;
        margin: 0 auto;
        padding: 90px 15px 20px;
    }

    .loginFormWrapper {
        min-height: unset;
    }
}
