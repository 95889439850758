.row {
    margin-bottom: 16px;
}

.valid {
    color: #82bc2f;
    white-space: pre-line;
}

.not-valid {
    color: #ff5c5c;
    white-space: pre-line;
}

.bold {
    font-weight: bold;
}
