.login__logo {
    display: block;
    margin-bottom: 50px;

    > img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
}

@media (max-width: 1024px) {
    .login__logo {
        margin-bottom: 46px;
    }
}
