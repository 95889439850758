@keyframes rotate {
    from {
        transform: 'rotate(0)';
    }
    to {
        transform: 'rotate(360deg)';
    }
}
.spinner {
    animation: rotate 1s infinite linear;
    height: 24px;
    width: 24px;
    display: inline-block;
}
.center {
    display: flex;
    justify-content: center;
    width: 100%;
}
.stretch {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    flex-basis: 100%;
    flex-grow: 1;
    height: 100%;
}
