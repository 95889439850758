.root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.option {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -11px;
    margin-right: 16px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}

.inputWrapper {
    padding: 9px;
}

.input {
}

.label {
    pointer-events: none;
    color: #666;
    font-size: 14px;
    font-family: HKNova;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.44px;
}
