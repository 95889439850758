.greetings {
    color: #fff;
}

.greetings__title {
    font-size: 37px;
    font-weight: 700;
    letter-spacing: 0.6px;
}

.greetings__subtitle {
    font-size: 26px;
    letter-spacing: 1.1px;
    font-weight: 500;
}

.greetings__text {
    font-size: 21px;
    max-width: 585px;
    letter-spacing: 1px;
    font-weight: 300;
    line-height: 1.35;
    margin: 0 auto 50px;
}

.greetings__title,
.greetings__subtitle {
    margin-bottom: 53px;
}

.greetings__title,
.greetings__subtitle,
.greetings__text {
    text-align: center;
}

@media (max-width: 1024px) {
    .greetings {
        padding: 90px 15px 20px;
    }
}
